import styled from '@emotion/styled';

export const Wrapper = styled.section`
  background-color: var(--color-secondary);
`;

export const List = styled.ul`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 80px;
  justify-content: center;
`;

export const ListItem = styled.li``;
