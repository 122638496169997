import { Inner, Subtitle, Title, Wrapper } from './hero.styles';

export const Hero = () => (
  <Wrapper>
    <Inner>
      <Title>Profesjonalne wykonawstwo</Title>
      <Subtitle>
        połączone z kompetentną
        <br />
        obsługą inżynierską
      </Subtitle>
    </Inner>
  </Wrapper>
);
