import { Container } from 'common/components/Container/Container';
import { Title } from 'common/components/Title/Title';

import { Abbr, List, ListItem, Wrapper } from './contact.styles';

export const Contact = () => (
  <Wrapper>
    <Container>
      <Title alternative>Kontakt</Title>

      <List>
        <ListItem>Kolonia Zabłudów 5/5, 16-060 Zabłudów<br/>ul. Św. Królowej Jadwigi 1, 64-111 Lipno</ListItem>
        <ListItem>
          <Abbr>Tel.:</Abbr>
          <a href="tel:+48798455350">+48 798 455 350</a>
        </ListItem>
        <ListItem>
          <Abbr>KRS</Abbr>
          0000869428
        </ListItem>
        <ListItem>
          <Abbr>NIP</Abbr>
          9662144866
        </ListItem>
        <ListItem>
          <Abbr>REGON</Abbr>
          387509442
        </ListItem>
      </List>
    </Container>
  </Wrapper>
);
