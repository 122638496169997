import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: min(100vh, var(--layout-map-height));
  width: 100%;
`;

export const MapContainer = styled.div`
  height: 100%;
`;
