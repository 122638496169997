import styled from '@emotion/styled';

export const Wrapper = styled.section`
  background-color: var(--color-light-gray);
`;

export const List = styled.ul``;
export const ListItem = styled.li`
  margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 30px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
export const Abbr = styled.abbr`
  display: block;
`;
